<template>
    <div class="investors_common">
        <div class="title">INVESTORS</div>
        <div class="pics">
            <img src="../../assets/in1.png" alt="" class="pic in1">
            <img src="../../assets/in2.png" alt="" class="pic in2">
            <img src="../../assets/in3.png" alt="" class="pic in3">
            <img src="../../assets/in4.png" alt="" class="pic in4">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.investors_common {
    background: #FBFBFB;
    padding: 3rem 0 0rem 0;
    margin-top: 2.1rem;
    .title {
        font-size: 1.3rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        padding-left: 1rem;
        text-align: center;
        letter-spacing: 0.28rem;
    }
    .pics {
        display: flex;
        overflow: scroll;
        margin-top: 3rem;
        padding-bottom: 2rem;
        padding-left: 1rem;
        img {
            height: 3.5rem;
            margin-right: 4rem;
        }
    }
}
</style>
