<template>
    <div class="form_common">
        <input v-model="phone" @focus="handleFocus" type="number" placeholder="Phone Number">
        <div v-if="error" class="error">{{error}}</div>
        <button @click="handleClick" :disabled="count < 60">{{count >= 60 ? `Text Me a Download Link` : `Resend after ${count}s` }}</button>
        <div class="tips" v-if="showTips">By clicking “Text me a download link”, you accept the Terms of Service</div>
    </div>
</template>

<script>
export default {
    props: {
        showTips: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            phone: "",
            error: "",
            count: 60
        };
    },
    methods: {
			handleClick() {
				if (!this.phone) {
					return
				} else {
					if (this.verify(this.phone)) {
                        this.error = '';
						this.sendMsg()
						this.countDown()
					} else {
						this.error = 'Incorrect phone number'
					}
				}
			},
			countDown() {
				this.count--
				if (this.count) {
					setTimeout(() => {
						this.countDown()
					}, 1000)
				} else {
					this.count = 60
				}
			},
			handleFocus() {
				this.errors = ''
			},
			verify(phone) {
				return /^(6|8|9)\d{7}$/.test(phone)
			},
			sendMsg() {
				// 开发环境
				// fetch('https://dev2-apimini.webuy.ren/wb_admin/api/send/sendMessage', {
				// 测试环境
				// fetch('https://dev-apimini.webuytw.com/wb_admin/api/send/sendMessage', {
				// 线上api
				fetch('https://apimini.webuyvn.com/wb_admin/api/send/sendMessage', {
						method: 'POST',
						body: JSON.stringify({
							phoneNumber: this.phone,
							countryCode: '65'
						}),
						headers: {
							'Content-type': 'application/json'
						}
					}).then(res => res.json())
					.then(res => {
						console.log(res)
						if (res.code !== 2000) {
							this.error = res.data
						}
					}).catch(err => {
						console.log(err)
						this.error = 'Please try again later'
					})
			}
    }
};
</script>

<style lang="scss" scoped>
.form_common {
    margin-top: 1rem;
    input {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        outline: none;
        height: 2.8rem;
        font-size: 1rem;
        box-sizing: border-box;
        border: none;
        padding-left: 1rem;
        border-radius: 0.7rem;
        &::placeholder {
            color: #999;
        }
    }
    .error {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        color: red;
        padding: 0.5rem 0;
        padding-bottom: 0;
    }
    button {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        outline: none;
        border: none;
        height: 2.8rem;
        box-sizing: border-box;
        border-radius: 0.7rem;
        font-size: 1.1rem;
        background: #0060ff;
        color: #fff;
        margin-top: 0.7rem;
        font-weight: 500;
    }
    .tips {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        padding-top: 1rem;
    }
}
</style>
