<template>
    <div class="about_common" id="about">
        <div class="title">ABOUT</div>
        <div class="sub-title">WEBUY is a social e-commerce platform that brings you the latest trend of group buying</div>
        <div class="desc">By purchasing with WEBUY, we can buy in bulk and get you lower prices. Download the app and join a group near you to start enjoying savings!</div>
        <div class="features">
            <div class="feature" v-for="feat in features" :key="feat.name">
                <img class="icon" :src="feat.icon">
                <div class="name">{{feat.name}}</div>
                <div class="content">{{feat.content}}</div>
            </div>
        </div>
        <div class="scences">
            <div v-for="(scence, index) in scences" :class="[index % 2 ? 'odd' : 'even','scence']" :key="scence.title">
                <img :src="scence.pic" alt="" class="pic">
                <div class="title">{{scence.title}}</div>
                <div class="desc">{{scence.desc}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            features: [
                {
                    icon: require("../../assets/discount.png"),
                    name: "Great Deals",
                    content: "Groupbuying and direct from supplier means 20-50% savings",
                },
                {
                    icon: require("../../assets/food-delivery.png"),
                    name: "Order Food You Love",
                    content: "No markup",
                },
                {
                    icon: require("../../assets/shopping-basket.png"),
                    name: "Video Shopping",
                    content:
                        "Add to cart directly from short videos, earn royalty by posting video reviews",
                },
                {
                    icon: require("../../assets/location.png"),
                    name: "Convenient",
                    content: "Free pick up 200m from your location island-wide with no min. spend",
                },
            ],
            scences: [
                {
                    pic: require("../../assets/scence1.jpg"),
                    title: "Fresh",
                    desc: "Discover a wide range of products – from fresh produce, frozen products, ready-to-eat meals and vouchers!",
                },
                {
                    pic: require("../../assets/scence2.jpg"),
                    title: "Lifestyle",
                    desc: "Fulfil your daily needs with WEBUY: dining vouchers, household items, beauty products and repair services",
                },
                {
                    pic: require("../../assets/scence3.jpg"),
                    title: "Food Delivery",
                    desc: "Enjoy food from popular restaurants from the comfort of your home – at attractive prices",
                },
                {
                    pic: require("../../assets/scence4.jpg"),
                    title: "Local Service",
                    desc: "Explore the latest, cool restaurants in the city and dine in with your friends and family",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.about_common {
    padding-top: 3rem;
    > .title {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: center;
        padding-bottom: 1.42rem;
        letter-spacing: 0.28rem;
    }
    > .sub-title {
        color: #0060ff;
        font-size: 1.7rem;
        font-weight: 600;
        text-align: center;
        padding: 0 1rem;
        line-height: 2.3rem;
    }
    > .desc {
        color: #5c5c5c;
        font-weight: 500;
        padding: 0 1rem;
        margin-top: 1.42rem;
        text-align: center;
    }
    > .features {
        margin-top: 2.2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding: 0 1rem;
        box-sizing: border-box;
        .feature {
            padding-bottom: 1.42rem;
            text-align: center;
            .icon {
                width: 3rem;
                height: 3rem;
            }
            .name {
                font-weight: 700;
                font-size: 1.3rem;
                margin-top: 0.7rem;
            }
            .content {
                color: #5c5c5c;
                font-weight: 500;
                margin-top: 0.5rem;
            }
        }
    }
    > .scences {
        margin-top: 1.5rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding: 5rem 1rem;
        box-sizing: border-box;
        background: #0060ff;
        color: #FFF;
        .scence {
            text-align: center;
            .pic {
                width: 100%;
            }
            .title {
                font-weight: 900;
                font-size: 1.4rem;
                margin-top: 0.7rem;
            }
            .desc {
                font-weight: 500;
                margin-top: 0.5rem;
            }
        }
        .odd {
            padding-top: 4.3rem;
        }
    }
}
</style>
