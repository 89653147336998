<template>
    <div id="contact" class="contact_common">
        <img src="../../assets/contact-icon.png" alt="" class="icon">
        <span class="msg">Want to work with us？Drop an email to <a target="_black" href="mailto:hr@webuy.global">hr@webuy.global</a></span>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.contact_common {
    background: #0060FF;
    padding: 2.1rem 1rem;
    display: flex;
    align-items: center;
    img {
        width: 3.2rem;
        height: 3.2rem;
    }
    span {
        margin-left: 1rem;
        color: #FFF;
        font-weight: 500;
        a {
            color: #FFF;
        }
    }
}
</style>
