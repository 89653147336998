<template>
    <div class="mobile_home">
        <div class="sticky">
            <Header></Header>
        </div>
        <Download></Download>
        <About></About>
        <Customers></Customers>
        <Comments></Comments>
        <Partners></Partners>
        <Merchants></Merchants>
        <Brands></Brands>
        <Investors></Investors>
        <NewsRoom></NewsRoom>
        <Contact></Contact>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/mobile/Header.vue";
import Download from "@/components/mobile/Download.vue";
import About from "@/components/mobile/About.vue";
import Customers from "@/components/mobile/Customers.vue";
import Comments from "@/components/mobile/Comments.vue";
import Partners from "@/components/mobile/Partners.vue";
import Merchants from "@/components/mobile/Merchants.vue";
import Brands from "@/components/mobile/Brands.vue";
import Investors from "@/components/mobile/Investors.vue";
import NewsRoom from "@/components/mobile/NewsRoom.vue";
import Contact from "@/components/mobile/Contact.vue";
import Footer from "@/components/mobile/Footer.vue";
export default {
    components: {
        Header,
        Download,
        About,
        Customers,
        Comments,
        Partners,
        Merchants,
        Brands,
        Investors,
        NewsRoom,
        Contact,
        Footer,
    },
    mounted() {
        setTimeout(() => {
            if (!this.$route.hash || this.$route.hash === "#") {
                return;
            }
            let dom = document.querySelector(this.$route.hash);
            if (dom) {
                dom.scrollIntoView();
            }
        }, 100);
    },
};
</script>

<style lang="scss" scoped>
.sticky {
    background: #FFF;
    position: sticky;
    top: 0;
    z-index: 9;
}
</style>
