<template>
    <div class="comments_common">
        <div class="title">
            <div>WHAT CUSTOMERS</div>
            <div>ARE SAYING</div>
        </div>
        <div class="sub-title">"My Favourite App"</div>
        <div class="user">- Satisfied Webuyer</div>
        <div class="list">
            <div class="item" v-for="(item, idx) in comments" :key="idx">
                <div class="name">{{item.name}}</div>
                <div class="stars">
                    <img class="star" src="../../assets/Star.png" alt="" v-for="(i,j) in item.star" :key="j">
                </div>
                <img :src="item.pic" class="pic" />
                <div class="goods">{{item.goods}}</div>
                <div class="content">{{item.content}}</div>
                <div class="type">{{item.type}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            comments: [
                {
                    name: "Kar***",
                    star: 5,
                    pic: require("../../assets/food1.png"),
                    goods: "[Famous Kulai YL Dim Sum Restaurant] Bao Series (5 Flavors)",
                    content: "Great product and much cheaper than wet market.",
                    type: "Bedok Groupbuy",
                },
                {
                    name: "DY***",
                    star: 5,
                    pic: require("../../assets/food2.png"),
                    goods: "Fresh Red Snapper Steak Cut (180 - 220g) X 3pcs",
                    content: "Taste good, individual vacuum packed.",
                    type: "Punggol Groupbuy",
                },
                {
                    name: "Joe***",
                    star: 5,
                    pic: require("../../assets/food3.png"),
                    goods: "Frozen Choice Sirloin Steak USA (250g x 2 pieces)",
                    content: "Steak is juicy, tender and has a minimum amount of fat",
                    type: "Jurong West Groupbuy",
                },
                {
                    name: "Cin***",
                    star: 5,
                    pic: require("../../assets/food4.png"),
                    goods: "Jollibean ($5 Cash Voucher)",
                    content: "Highly recommended! great deals! Easy to use",
                    type: "Tiong Bahru Groupbuy",
                },
                {
                    name: "Mom***",
                    star: 5,
                    pic: require("../../assets/food5.png"),
                    goods: "[Lunch & Dinner] TungLok - Roast Irish Duck (Best Duck in the World) FDP",
                    content: "Yummy juicy duck with crispy skin. very good buy",
                    type: "Seng Kang Groupbuy",
                },
                {
                    name: "Jes***",
                    star: 5,
                    pic: require("../../assets/food6.png"),
                    goods: "Pahang WEBUY MSW Durian 400g/box",
                    content: "Beautiful yellow flesh! Great Quality!",
                    type: "Hougang Groupbuy",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.comments_common {
    padding-bottom: 2rem;
    .title {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 700;
        padding: 5rem 0 1.42rem 0;
        > div {
            letter-spacing: 0.3rem;
        }
    }
    .sub-title {
        color: #0060ff;
        font-size: 1.7rem;
        font-weight: 900;
        text-align: center;
    }
    .user {
        text-align: center;
        margin-top: 1.42rem;
        font-size: 1.2rem;
        color: #5c5c5c;
    }
    .list {
        display: flex;
        width: calc(100vw - 1rem);
        margin-left: 1rem;
        overflow: scroll;
        padding: 3rem 0;
        padding-bottom: 1rem;
        .item {
            margin-right: 2rem;
            .name {
                width: 5rem;
                background: rgba(0, 96, 255, 0.1);
                border-radius: 0.6rem;
                font-weight: 600;
                font-size: 1rem;
                line-height: 2rem;
                text-align: center;
                color: #0060ff;
                margin-bottom: 1.2rem;
            }
            .stars {
                margin-bottom: 1.2rem;
                .star {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
            }
            .goods {
                font-size: 1.1rem;
                font-weight: 700;
            }
            .content {
                font-size: 1.7rem;
                font-weight: 900;
                color: #5C5C5C;
                margin-top: 0.7rem;
            }
            .type {
                margin-top: 0.7rem;
                color: #5C5C5C;
            }
        }
    }
}
</style>
