<template>
    <div class="download_common">
        <div class="content">
            <div class="slogen">Discover and share with your neighbour</div>
            <div class="description">Create, watch, and share short, entertaining videos with friends and neighbours - whether it's an unboxing or dining experience, we bring you closer to the people and things you love.</div>
            <video webkit-playsinline="true" playsinline="true" @click="setVideo" ref="video" autoplay muted loop :src="videoUrl"></video>
            <Form :showTips="true" id="download"></Form>
            <div class="store">
                <a href="https://apps.apple.com/sg/app/webuy-sg/id1522669075">
                    <img src="../../assets/app_store.png" alt="">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.webuy.sg&hl=en_US&gl=US">
                    <img src="../../assets/google_play.png" alt="">
                </a>
                <a href="javascript: void;">
                    <img @click="show" src="../../assets/wechat.png" alt="">
                </a>
            </div>
        </div>
        <div class="modal" v-show="showModal">
            <div class="modal_content">
                <img src="../../assets/wechat-code.png" alt="">
                <div class="info">
                    <div class="title">Scan to Open</div>
                    <div class="sub-title">Start shopping on WEBUY in We Chat</div>
                    <div class="button" @click="hideModal">Got it!</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from "@/components/mobile/Form.vue";
export default {
    components: {
        Form,
    },
    data() {
        return {
            muted: true,
            videoUrl: "https://prod-webuysg.oss.webuy.ren/video/top1.mp4",
            showModal: false,
        };
    },
    methods: {
        setVideo() {
            const $video = this.$refs.video;
            $video.muted = !$video.muted;
            this.muted = $video.muted;
        },
        hideModal() {
            this.showModal = false;
        },
        show() {
            this.showModal = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.download_common {
    background-image: url("../../assets/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;

    .content {
        backdrop-filter: blur(2px);
        background: rgba($color: #000000, $alpha: 0.3);
        padding-bottom: 1rem;
        .slogen {
            font-weight: 600;
            font-size: 1.7rem;
            line-height: 2.3rem;
            padding: 1.5rem 1rem;
        }
        .description {
            font-weight: 500;
            padding: 0 1rem;
            padding-bottom: 1.5rem;
        }
        video {
            width: calc(100% - 2rem);
            margin-left: 1rem;
            border-radius: 2rem;
            object-fit: cover;
        }
        .store {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            width: calc(100% - 2rem);
            margin-left: 1rem;
            img {
                height: 2.3rem;
            }
        }
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba($color: #000000, $alpha: 0.5);
        .modal_content {
            width: calc(100% - 2rem);
            margin-left: 1rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1rem;
            background: #FFF;
            padding: 2rem;
            box-sizing: border-box;
            img {
                width: 100%;
            }
            .info {
                margin-top: 1rem;
            }
            .title {
                font-weight: 500;
                font-size: 2rem;
                color: #02283f;
            }
            .sub-title {
                font-weight: 500;
                font-size: 1.2rem;
                color: #5c5c5c;
            }
            .button {
                background: #0060ff;
                border-radius: 1rem;
                color: #fff;
                font-weight: 500;
                font-size: 1rem;
                text-align: center;
                height: 2.5rem;
                margin-top: 1rem;
                line-height: 2.5rem;
                cursor: pointer;
            }
        }
    }
}
</style>
