<template>
    <div class="footer_common">
        <div class="info">
            <img class="logo" src="../../assets/w.png" alt="">
            <span class="copy">©2021 WEBUY</span>
        </div>
        <nav>
            <span>Terms of Service</span>
            <span>Privacy Policy</span>
            <span>Cookie Settings</span>
        </nav>
        <div class="icons">
            <a target="_black" href="https://www.facebook.com/webuysingapore/"><img src="../../assets/icon-facebook.png" alt=""></a>
            <a target="_black" href="https://www.instagram.com/webuy_sg/"><img src="../../assets/icon-ins.png" alt=""></a>
            <a target="_black" href="https://www.linkedin.com/company/webuysg"><img src="../../assets/icon-lin.png" alt=""></a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.footer_common {
    padding: 3rem 1rem;
    color: #999;
    font-size: 1.2rem;
    .info {
        display: flex;
        align-items: center;
        .logo {
            width: 3rem;
            height: 3rem;
        }
        .copy {
            margin-left: 1rem;
        }
    }
    nav {
        margin: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            font-size: 1rem;
        }
    }
    .icons {
        a {
            margin-right: 3rem;
            img {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}
</style>
