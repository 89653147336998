<template>
    <div class="bands_common">
        <div class="brand_logos">
            <img src="../../assets/brand1.png" alt="">
            <img src="../../assets/brand2.png" alt="">
            <img class="brand3" src="../../assets/brand3.png" alt="">
            <img src="../../assets/brand4.png" alt="">
            <img src="../../assets/brand5.png" alt="">
        </div>
        <div class="brand_swiper">
            <img class="left_button slide_button" src="../../assets/arrow-left.png" @click="slidePrev" />
            <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
                <swiper-slide class="swiper_item" v-for="(b, idx) in brands" :key="idx">
                    <div class="card">
                        <img class="logo" :src="b.logo">
                        <div class="title">{{b.title}}</div>
                        <div class="content">{{b.content}}</div>
                        <div class="user">{{b.user}}</div>
                    </div>
                </swiper-slide>
            </swiper>
            <img class="right-button slide_button" src="../../assets/arrow-right.png" @click="slideNext" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            swiperOptions: {
                pagination: {
                    el: ".swiper-pagination",
                },
                loop: true,
            },
            brands: [
                {
                    logo: require("../../assets/brand4.png"),
                    title: "New heights",
                    content: "Our customers keep coming back for more.",
                    user: "Sandra Tan, Marketing Director",
                },
                {
                    logo: require("../../assets/brand5.png"),
                    title: "An absolute delight",
                    content: "We’ve reached more customers than ever before! ",
                    user: "Max Lee, CEO",
                },
            ],
        };
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
    },
    methods: {
        slidePrev() {
            this.swiper.slidePrev();
        },
        slideNext() {
            this.swiper.slideNext();
        },
    },
};
</script>

<style lang="scss" scoped>
.bands_common {
    .brand_logos {
        display: flex;
        overflow: scroll;
        padding-left: 1rem;
        padding-bottom: 1rem;
        img {
            height: 7rem;
            margin-right: 1rem;
        }
    }
    .brand_swiper {
        margin-top: 5rem;
        display: flex;
        align-items: center;
        width: calc(100% - 2rem);
        margin-left: 1rem;
        .slide_button {
            width: 1rem;
            height: 1.7rem;
        }
        .swiper {
            margin: 0 1.5rem;
            .swiper_item {
                .card {
                    background: #FBFBFB;
                    padding: 1rem 2rem;
                    margin: 0 auto;
                    box-sizing: border-box;
                    .logo {
                        width: 4rem;
                        height: 4rem;
                    }
                    .title {
                        color: #5C5C5C;
                        font-size: 2rem;
                        font-weight: 900;
                        margin-top: 0.5rem;
                    }
                    .content {
                        color: #02283F;
                        font-size: 1.2rem;
                        font-weight: 700;
                        margin-top: 0.8rem;
                    }
                    .user {
                        color: #5C5C5C;
                        margin-top: 0.8rem;
                    }
                }
            }
        }
    }
}
</style>
