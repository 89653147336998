<template>
    <div class="video_swiper">
        <img :src="theme==='blue' ? blueLeft : whiteLeft" @click="slidePrev" class="left-button button">
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(videoUrl, idx) in videos" :key="idx">
                <video webkit-playsinline="true" playsinline="true" :ref="'video'+idx" autoplay muted loop :src="videoUrl"></video>
            </swiper-slide>
        </swiper>
        <img :src="theme==='blue' ? blueRight : whiteRight" @click="slideNext" class="right-button button">
    </div>
</template>

<script>
export default {
    props: ["videos", "theme"],
    data() {
        return {
            swiperOptions: {
                pagination: {
                    el: ".swiper-pagination",
                },
                loop: true,
            },
            whiteLeft: require("../../assets/arrow-white-left.png"),
            whiteRight: require("../../assets/arrow-white-right.png"),
            blueLeft: require("../../assets/arrow-left.png"),
            blueRight: require("../../assets/arrow-right.png"),
        };
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
    },
    methods: {
        slidePrev() {
            this.swiper.slidePrev();
            const index = this.swiper.activeIndex;
            this.swiper.el.querySelectorAll("video")[index].currentTime = 0;
        },
        slideNext() {
            this.swiper.slideNext();
            const index = this.swiper.activeIndex;
            this.swiper.el.querySelectorAll("video")[index].currentTime = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.video_swiper {
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    .button {
        width: 1rem;
        height: 1.7rem;
    }
    .swiper {
        background: transparent;
        border-radius: 2rem;
        margin: 0 2rem;
        width: 100%;
        height: 100%;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 2rem;
        }
    }
}
</style>
