<template>
    <div class="customers_common" id="customers">
        <div class="content">
            <div class="title">{{content.title}}</div>
            <div class="sub_title">{{content.subTitle}}</div>
            <div class="cards">
                <div v-for="(v) of content.cards" :key="v.name" class="card">
                    <img class="icon" :src="v.icon" />
                    <div class="name">{{v.name}}</div>
                    <div class="desc">{{v.desc}}</div>
                </div>
            </div>
            <Form></Form>
            <VideoSwiper theme="white" :videos="videos"></VideoSwiper>
        </div>
    </div>
</template>

<script>
import Form from "@/components/mobile/Form.vue";
import VideoSwiper from "@/components/mobile/VideoSwiper.vue";
export default {
    components: {
        Form,
        VideoSwiper
    },
    data() {
        return {
            content: {
                title: "CUSTOMERS",
                subTitle: "Shopping made easy",
                cards: [
                    {
                        icon: require("../../assets/happy.png"),
                        name: "Social",
                        desc: "Share honest reviews, video your experience - shopping online has never been more authentic!",
                    },
                    {
                        icon: require("../../assets/quality.png"),
                        name: "Variety & Quality",
                        desc: "Over 1,000 products, 40 brands, and 20 countries. Quality guaranteed or 100% refund!",
                    },
                ],
            },
            videos: [
                'https://prod-webuysg.oss.webuy.ren/video/customer1.mp4',
                'https://prod-webuysg.oss.webuy.ren/video/customer2.mp4',
                'https://prod-webuysg.oss.webuy.ren/video/customer3.mp4',
                'https://prod-webuysg.oss.webuy.ren/video/customer4.mp4',
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.customers_common {
    background-image: url("../../assets/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .content {
        backdrop-filter: blur(2px);
        background: rgba($color: #000000, $alpha: 0.3);
        padding-bottom: 1rem;
        color: #FFF;
        .title {
            font-size: 1.3rem;
            font-weight: 600;
            letter-spacing: 0.28rem;
            padding: 3rem 1rem 1rem 1rem;
        }
        .sub_title {
            font-size: 1.7rem;
            font-weight: 900;
            padding: 0 1rem;
            padding-bottom: 2rem;
        }
        .cards {
            padding: 0 1rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            padding-bottom: 1rem;
            .icon {
                width: 3rem;
                height: 3rem;
            }
            .name {
                font-weight: 800;
                font-size: 1.3rem;
                padding: 1rem 0 0.85rem 0;
            }
            .desc {
                font-weight: 500;
            }
        }
    }
}
</style>
