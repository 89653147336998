<template>
    <div class="merchants_common" id="merchants">
        <div class="title">MERCHANTS</div>
        <div class="sub_title">A game changer</div>
        <div class="cards">
            <div class="card" v-for="(c,k) in cards" :key="k">
                <img class="icon" :src="c.icon" />
                <div class="name">{{c.name}}</div>
                <div class="desc">{{c.desc}}</div>
            </div>
        </div>
        <a href="https://www.webuy.com.sg/merchants" target="_black" class="link-button">Join as a Merchant</a>
        <VideoSwiper theme="blue" :videos="videos"></VideoSwiper>
    </div>
</template>

<script>
import VideoSwiper from "@/components/mobile/VideoSwiper.vue";
export default {
    components: {
        VideoSwiper,
    },
    data() {
        return {
            cards: [
                {
                    icon: require("../../assets/light-blue.png"),
                    name: "Grow Your Business",
                    desc: "Reach your next customers at competitive prices",
                },
                {
                    icon: require("../../assets/heart-cart-blue.png"),
                    name: "Build  Brand Loyalty",
                    desc: "Engaging features that develop strong customer relationship",
                },
            ],
            videos: [
                "https://prod-webuysg.oss.webuy.ren/video/merchant2.mp4",
                "https://prod-webuysg.oss.webuy.ren/video/merchant3.mp4",
                "https://prod-webuysg.oss.webuy.ren/video/merchant4.mp4",
                "https://prod-webuysg.oss.webuy.ren/video/merchant1.mp4",
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.merchants_common {
    background: #FFF;
    padding: 3.5rem 0;
    .title {
        font-size: 1.3rem;
        font-weight: 700;
        padding-left: 1rem;
        letter-spacing: 0.28rem;
    }
    .sub_title {
        color: #0060ff;
        font-size: 1.7rem;
        font-weight: 900;
        margin-left: 1rem;
        margin-top: 1.42rem;
    }
    .cards {
        padding: 0 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding-bottom: 1rem;
        margin-top: 2rem;
        .icon {
            width: 3rem;
            height: 3rem;
        }
        .name {
            color: #02283f;
            font-weight: 800;
            font-size: 1.3rem;
            padding: 1rem 0 0.85rem 0;
        }
        .desc {
            font-weight: 500;
            color: #5c5c5c;
        }
    }
    .link-button {
        display: block;
        background: #0060ff;
        width: 18rem;
        text-align: center;
        height: 2.6rem;
        line-height: 2.6rem;
        color: #fff;
        margin: 0 auto;
        font-weight: 700;
        border-radius: 99999999px;
        text-decoration: none;
        margin-top: 2.5rem;
        font-size: 1.1rem;
    }
}
</style>
